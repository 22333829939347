@import url('https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:wght@400;600&display=swap');

@font-face {
  font-family: 'MessinaSans';
  font-weight: 200;
  src: local('MessinaSans'), url(./assets/fonts/MessinaSans-Light.otf) format('opentype');
}

@font-face {
  font-family: 'MessinaSans';
  src: local('MessinaSans'), url(./assets/fonts/MessinaSans-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'MessinaSans';
  font-weight: 600;
  src: local('MessinaSans'), url(./assets/fonts/MessinaSans-Bold.otf) format('opentype');
}


h1, h2, h3, h4, h5 {
  font-family: 'Ibarra Real Nova', serif;
}
a, p, small, .btn, input, textarea{
  font-family: 'MessinaSans', serif;
}

.small, small {
  font-size: .575em;
}
.menu-link {
  text-decoration: none;
  color: black;
}
.menu-link-active {
  font-weight: 600;
}

.menu-link:hover, .menu-link:active {
  color: black;
  text-decoration: none;
}

.text-h-flip {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.btn, .form-control {
  border-radius: 0;
}

.border-around {
  border: 1px solid #000;
  padding: 10px;
}

.accordion-button {
  background-color: white;
}

.bg-caki {
  background-color: #dda15ed6;
}

.modal-header, .modal-footer, .modal-content {
  border-radius: 0px;
}

video {
  filter: blur(30px);
  width: 100%;
}

.video-gradient {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.video-gradient:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(131,58,180);
  background: linear-gradient(121deg, rgba(131,58,180,0.3) 0%, rgba(253,29,29,0.3) 50%, rgba(252,176,69,0.3) 100%);
  z-index: 10;
  filter: blur(30px);
}

.img-blur {
  filter: blur(20px);
  width: 100%;
}

.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.loader {
  position: relative;
  width: 100px;
  height: 100px;
  top: calc(50% - 200px);
  left: calc(50% - 50px);
  z-index: 9999;
}

.scroll-80h {
  height: 80vh ;
}

.border1 {
  border: 1px solid;
}

.progress {
  border-radius: 0px;
  font-size: 0.5rem;
  height: 0.7rem;
}

.progress-bar {
  background-color: #000;
}

.form-select {
  border-radius: 0;
}

.badge {
  font-size: 0.6rem;
  padding: 0.20em 0.35rem;
  height: 18px;
  border-radius: 0;
  line-height: inherit;
}

small, .small {
  font-size: 0.675em;
}

.sticky-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.multi-character {
  border-color: #c6c6c6;
  font-family: 'MessinaSans', serif;
}

.multi-character--inactive {
  background-color: bisque;
}

.border-bot {
  border-bottom: 3px solid black;
}

.inner-img-tag {
  z-index: 15;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.no-blur {
  filter: blur(0px);
}

.list-group-item.active {
  background-color: black;
  border-color: #666;
}

.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-check-input:checked {
  background-color: black;
  border-color: black;
}